import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { QuickLink } from '../models/quickLink.model';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class QuickLinksService {
  private QuickLinksApiUrl = environment.apiUrl + '/api/QuickLink/';
  constructor(private http: HttpClient, private settings: SettingsProvider) { }

  getQuickLinks(): Observable<QuickLink[]> {
    return this.http
      .get<QuickLink[]>(this.QuickLinksApiUrl)
      .pipe(tap((data) => data), catchError(this.handleError<QuickLink[]>()));
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result);
    };
  }
}
