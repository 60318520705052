import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MOTIF_MODAL_DATA, MotifModal, MotifModalModule, MotifModule } from '@ey-xd/ng-motif';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Labels } from 'src/app/locale/en/labels.en';
import { MOTIF_ICONS } from 'src/app/constants/motif-iconts.constant';
import { WorkspaceService } from 'src/app/workspace/workspace.service';

@Component({
  selector: 'app-confirm',
  standalone: true,
  imports: [MotifModule, MotifModalModule, ModalModule, CommonModule],
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {
  title: string;
  content: any;
  confirmBtnName: string;
  cancelBtnName: string;
  isDeleteButton = false;
  popupOpen:boolean = false;
  btnColor: string = '';
  deletingAllQuestionsAlert = Labels.DeletingAllQuestionsAlert;

  constructor(
    public modal: MotifModal,
    private workspace: WorkspaceService,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
  ) {
    this.title = motifData?.initialState.title;
    this.content = motifData?.initialState.content;
    this.confirmBtnName = motifData?.initialState.confirmBtnName;
    this.cancelBtnName = motifData?.initialState.cancelBtnName;
    this.isDeleteButton = motifData?.initialState.isDeleteButton;
    this.btnColor = motifData?.initialState.btnColor;
    this.workspace.popupOpened.subscribe(item=>{
      this.popupOpen = item;
    })
    this.workspace.popupOpened.next(true);
  }

  confirmCancel(){
    this.workspace.routeChanged.next(false);
    this.workspace.popupOpened.next(true);
  }

  closePopup(){
    this.workspace.popupOpened.next(false)
  }

  iconFunction(icon) {
    return MOTIF_ICONS[icon];
  }
}
