import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { QuestionUploadService } from "../services/question-upload.service";
@Injectable({
    providedIn: 'root'
  })
  export class QuestionUploadGuard  {

    isAuthorized: boolean = false;
    
    constructor(private questionUploadService: QuestionUploadService, private router: Router) { 
    }
  
    async canActivate(): Promise<any> {
      return await Promise.resolve(
        this.questionUploadService.checkAccess().subscribe(res=>{
          this.isAuthorized = res;
          if(this.isAuthorized){
            return true;
          }
          else{
            this.router.navigateByUrl('/unauthorized');
            return false;
          }
        })
      ) 
    }
  }