import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { QuillModule } from 'ngx-quill';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { AuthGuard } from './session_management/auth.guard';
import { EngagementGuard } from './_guard/engagement.guard';
import { LoggingService } from './_services/logging.service';
import { CreateWorkspaceComponent } from './workspace/create-workspace/create-workspace.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { checkDirtyState } from './readiness/questionairrelibrary/checkFormDirtyState';
import { ScrollSpyDirective } from './directive/scroll-spy.directive';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { SharedModule } from './shared/shared.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { LogoutComponent } from './logout/logout.component';
import { SettingsProvider } from './settingsProvider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { AgGridModule } from '@ag-grid-community/angular';
import { PubSubService } from './_services/pubsub.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgChartsModule } from 'ng2-charts';
import { BenchmarkService } from './_services/benchmark.service';
import { OwnerService } from './_services/owner.service';
import { CookieService } from 'ngx-cookie-service';
import { MotifModule, MotifModalModule } from '@ey-xd/ng-motif';
import { DeletePopupComponent } from './workspace/delete-workspace/delete-workspace.component';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './factory';
import { RenewSessionComponent } from './session_management/components/renew-session/renew-session.component';
import { SessionInterceptor } from './session_management/session.interceptor';
import { SessionService } from './session_management/session-service.service';
import { QuestionUploadComponent } from './question-upload/question-upload.component';

import { MotifTreeModule } from '@ey-xd/ng-motif';
import { NoteTemplateComponent } from './readiness/notes/components/note-template/note-template.component';
import { DateCreatedComponent } from './readiness/notes/components/date-created/date-created.component';
import { ActionComponent } from './readiness/notes/components/action/action.component';

import { MotifAccordionModule } from '@ey-xd/ng-motif';
import { SelectRendererComponent } from './readiness/questionairrelibrary/rendering-elements/dropdownRender/select-render.component';
import { CheckBoxInScopeRendererComponent } from './readiness/questionairrelibrary/rendering-elements/checkboxInScope/checkbox-inscope-render.component';
import { CheckBoxPPTRendererComponent } from './readiness/questionairrelibrary/rendering-elements/checkboxPPT/checkbox-ppt-render.component';
import { ActionButtonRendererComponent } from './readiness/questionairrelibrary/rendering-elements/ActionButtonRender/action-button-render.component';
import { MotifCommentModule } from '@ey-xd/ng-motif';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export function init(settingsProvider: SettingsProvider) {
  return () => settingsProvider.loadConfig();
}

export function getAlertConfig(): TooltipConfig {
  const is_touch_device = 'ontouchstart' in window;
  return Object.assign(new TooltipConfig(), {
    triggers: 'hover'
  });
}


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,

    CreateWorkspaceComponent,
    ScrollSpyDirective,
    CreateUserComponent,
    UnauthorizedComponent,
    NotfoundComponent,
    LogoutComponent,
    RenewSessionComponent,
    DeletePopupComponent,
    QuestionUploadComponent,
    NoteTemplateComponent,
    DateCreatedComponent,
    ActionComponent,
    ActionButtonRendererComponent,
    SelectRendererComponent,
    CheckBoxPPTRendererComponent,
    CheckBoxInScopeRendererComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    RouterModule.forRoot(appRoutes, {}),
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    DragDropModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    MatStepperModule,
    MatIconModule,
    ButtonsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    QuillModule.forRoot(),
    AgGridModule,
    MultiSelectModule,
    ListboxModule,
    ScrollingModule,
    NgChartsModule,
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
    MotifModule,
    MotifModalModule,
    MotifTreeModule,
    MotifAccordionModule,
    MotifCommentModule
  ],
  providers: [
    ErrorInterceptorProvider,
    AuthGuard,
    EngagementGuard,
    LoggingService,
    PubSubService,
    BenchmarkService,
    OwnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: 'canDeactivate',
      useValue: checkDirtyState
    },
    SettingsProvider,
    CookieService,
    SessionService,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }