import { Component } from '@angular/core';
import { Router} from '@angular/router';
import { environment } from 'src/assets/client-config';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  onPremises: boolean;

  constructor(public router: Router) {
    this.onPremises = environment.onPremises;
  }

  login() {
    this.router.navigateByUrl('/home');
  }
}
