<motif-modal-title class="title">
     About IPO Enabler
  <button class="close-btn" motifIconButton aria-label="Close modal" size="medium" [motif-modal-close]="false">
  <motif-icon class="motif-modal-header-close-icon" [iconFunction]="closeIcon()" size="20"></motif-icon>
</button>
</motif-modal-title>
<motif-modal-content> 
  <div class="textabout">
      Version: IPOE.{{env.appVersion}}  
  <div class="textsection">
     {{env.appReleaseYear}} EY, LLP IPO Enabler    
  </div>
</div>  
</motif-modal-content>
<motif-modal-actions class="action-class">
  <button class="cancelbutton" motifButton motif-modal-close color="secondary" aria-label="Cancel">Close</button>
</motif-modal-actions> 