<div class="motif-container accessdenied">                
  <div class="iconblock">
      <motif-icon [src]="'/assets/icons/content/ic_block_24px.svg'"></motif-icon>
  </div>
  <div class="textaccess">
        Access denied
  </div>
  <div class="textdetail">               
       You do not have access to IPO Enabler. Please get in touch with the administrator to request access.                 
   </div>
</div>
