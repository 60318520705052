import { Component, Inject } from '@angular/core';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
import { Workspace } from '../workspace';
import { WorkspaceService } from '../workspace.service';
import moment from 'moment';
import { ToasterService } from 'src/app/shared/toast/toaster.service';
import { Labels } from 'src/app/locale/en/labels.en';
import { IconoirXmark } from '@ey-xd/motif-icon';
@Component({
    selector: 'app-delete-workspace',
    templateUrl: './delete-workspace.component.html',
    styleUrls: ['./delete-workspace.component.scss']
})
export class DeletePopupComponent {
    title: string;
    content: any;
    confirmBtnName: string;
    cancelBtnName: string;
    isDeleteButton = false;
    workspace: Workspace;
    deletesuccess:boolean;
    constructor(
        public modal: MotifModal,
        @Inject(MOTIF_MODAL_DATA) motifData: any,
        private workspaceService: WorkspaceService,
        private toastr: ToasterService
    ) {
        this.title = motifData?.initialState.title;
        this.content = motifData?.initialState.content;
        this.confirmBtnName = motifData?.initialState.confirmBtnName;
        this.cancelBtnName = motifData?.initialState.cancelBtnName;
        this.workspace = motifData?.initialState.workspace
    }

    deleteWorkspace() {
        this.workspaceService.deleteWorkspace(this.workspace).subscribe({
          next: (res) => {
                this.workspaceService.getWorkspaces().subscribe({
                    next: workspaces => {
                      this.deletesuccess=true;
                      this.toastr.success(Labels.ModalDeleteWSSuccess)
                      workspaces.forEach(workspace => {
                        workspace.modifiedOn = moment.utc(workspace.modifiedOn).format('DD MMMM YYYY');  
                        workspace.countryName = workspace.country.name;       
                      });
                      this.workspaceService.workspaces.next(workspaces);
                    },
                  });
            },
          error: (error) => {
              this.deletesuccess=false;
              this.toastr.error(Labels.UnExpectedErrorWSDelete);
            }
          });
    }

    closeIcon() {
      return IconoirXmark;
    }
}
