import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { WorkspaceService } from '../workspace.service';
import { Workspace } from '../workspace';
import { Labels } from 'src/app/locale/en/labels.en';
import { ReadinessScoreService } from '../../readiness/dashboard/readiness-score.service';
import { QuestionMetadatService } from '../../readiness/questionairrelibrary/questionmetadata.service';
import { EntitlementService } from '../../_services/entitlementService';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/_services/common.service';
import { Observable, Subject, map } from 'rxjs';
import { UsermanagementService } from 'src/app/_services/usermanagement.service';
import { MOTIF_MODAL_DATA } from '@ey-xd/ng-motif';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdUser } from 'src/app/models/user.model';
import moment from 'moment';
import { ToasterService } from 'src/app/shared/toast/toaster.service';
import { ValidationConstant } from 'src/app/constants/validation-constant';
import { IconoirXmark } from '@ey-xd/motif-icon';

@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss']
})
export class CreateWorkspaceComponent implements OnInit {
  newWorkspace = new Workspace();
  newProjectForm: UntypedFormGroup;
  title: string;
  closeBtnName: string;
  saveBtnName: string;
  workSpaceName: string;
  guid: string;
  resetEngagement: boolean;
  country: any[];
  industry: any[];
  alerts: any[] = [];
  isEdit = false;
  sub: any;
  partnerDataSource: Observable<any>;
  snrMgrDataSource: Observable<any>;
  @ViewChild('newWorkspaceForm', { static: true }) myForm: any;
  pattern = ".*[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}.*";
  pattern1=ValidationConstant.Pattern;
  patternMsg=ValidationConstant.PatternIssueMsg;
  spaceMsg=ValidationConstant.SpaceIssueMsg;
  partners = [];
  seniorManagers = [];
  loadddlCompletes = new Subject<boolean>();

  constructor(
    private workspaceService: WorkspaceService,
    public readinessScoreService: ReadinessScoreService,
    public questionMetadatService: QuestionMetadatService,
    public entitlementService: EntitlementService,
    public commonService: CommonService,
    public userService: UsermanagementService,
    private toastr: ToasterService,
    @Inject(MOTIF_MODAL_DATA) data: any,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.guid = data.initialState.guid;
    this.title = data.initialState.title;
    this.resetEngagement = data.initialState.resetEngagement;
    this.saveBtnName = data.initialState.saveBtnName;
  }
  ngOnInit() {
    this.loadDdl();
    this.generateForm();
    this.newWorkspace.countryId = '';
    this.newWorkspace.industryId = '';
    this.partnerDataSource = new Observable((observer: any) => {
      this.markUserAsError('partner');
      this.userService
        .searchUser(this.newWorkspace.partnerName)
        .subscribe((result: any) => {
          observer.next(result);
        });
    });
    this.snrMgrDataSource = new Observable((observer: any) => {
      this.markUserAsError('seniormanager');
      this.userService
        .searchUser(this.newWorkspace.seniorManagerName)
        .subscribe((result: any) => {       
          observer.next(result);
        });
    });
    
    if(this.guid !== '' && (this.resetEngagement === undefined || this.resetEngagement === false)) {      
      this.isEdit = true;  
      this.loadddlCompletes.subscribe((res) => {
        if(res) {
          this.workspaceService.getWorkspace(this.guid).subscribe({
            next: workspace => { 
              this.newWorkspace = workspace;
              this.newProjectForm.patchValue(workspace);
            },
            error: error => {
              this.toastr.error(Labels.ServerError)
            }
          });
        }
      });
    } 
  }

  generateForm() {
    this.newProjectForm = this.fb.group({
      name: ['', Validators.required],
      clientName: ['', Validators.required],
      industryId: ['', Validators.required],
      partnerName: ['', Validators.required],
      seniorManagerName: ['', Validators.required],
      city: ['', Validators.required],
      countryId: ['', Validators.required],
    })


    this.newProjectForm.controls.industryId.valueChanges.subscribe((value) => {
      if (this.newProjectForm.controls.industryId.dirty) {
        this.newProjectForm.controls.industryId.markAsPristine();
      }
    });

    this.newProjectForm.controls.countryId.valueChanges.subscribe((value) => {
      if (this.newProjectForm.controls.countryId.dirty) {
        this.newProjectForm.controls.countryId.markAsPristine();
      }
    });
  }
  /**
 * Control Validators
 * @controlName {string}
 * @return {boolean}
 * */
  validator(controlName: string) {
    return this.newProjectForm?.controls[controlName].invalid
      &&
      this.newProjectForm.controls[controlName].dirty
      &&
      this.newProjectForm.controls[controlName].hasError('required')
  }
  spaceValidator(controlName: string) {
    let spaceflag = this.commonService.hasWhitespaceOrNewlineAtEdges(this.newProjectForm?.controls[controlName].value);

    let pattern =this.pattern1;
    let patternFlag= pattern.test(this.newProjectForm?.controls[controlName].value);

    let requiredflag= this.newProjectForm?.controls[controlName].value == ''? true:false;
    if(!(!spaceflag && patternFlag && !requiredflag))
    this.newProjectForm?.controls[controlName].setErrors({spaceIssue: spaceflag, patternIssue: !patternFlag, required:requiredflag});
  }

  spaceValidatorCity(controlName: string) {
    let spaceflag = this.commonService.hasWhitespaceOrNewlineAtEdges(this.newProjectForm?.controls[controlName].value);

    let pattern =/^[a-zA-Z ]*$/;
    let patternFlag= pattern.test(this.newProjectForm?.controls[controlName].value);

    let requiredflag= this.newProjectForm?.controls[controlName].value == ''? true:false;
    if(!(!spaceflag && patternFlag && !requiredflag))
    this.newProjectForm?.controls[controlName].setErrors({spaceIssue: spaceflag, patternIssue: !patternFlag, required:requiredflag});
  }
  loadPartners(e) {
    this.userService.searchUser(this.newProjectForm.controls.partnerName.value).pipe(
      map((clients: Array<AdUser>) =>
        clients?.map((client: AdUser) => `${client?.displayName} (${client?.mail})`)
      ),
      map((clients: Array<string>) => this._filterTypeaheadList(e, clients).slice(0).sort())      
    ).subscribe((results) => {        
         this.partners = Array.from(results);
    })
  }

  loadSeniorManagers(e) {
    this.userService.searchUser(this.newProjectForm.controls.seniorManagerName.value).pipe(
      map((clients: Array<AdUser>) =>
        clients?.map((client: AdUser) => `${client?.displayName} (${client?.mail})`)
      ),
      map((clients: Array<string>) => this._filterTypeaheadList(e.target.value, clients).slice(0).sort())
    ).subscribe((results) => {
      this.seniorManagers = Array.from(results);
    })
  }

  private _filterTypeaheadList(term: string, dataset: Array<string>): Array<string> { 
    if (!term) return new Array<string>();
    return dataset?.filter((v) => (v ? v.toLowerCase().indexOf(term.toLowerCase()) > -1 : false))?.slice(0, 25);
  }

  saveWorkspace() {    
    this.workSpaceName = this.newWorkspace.name;
    const payload = this.newProjectForm.getRawValue();   
    if (this.guid !== '') {
      if (
        this.resetEngagement === false ||
        this.resetEngagement === undefined
      ) {
        this.workspaceService.updateWorkspace(payload, this.guid).subscribe({
          next: () => {
              this.toastr.success(Labels.ModalUpdateWSSuccess)
              this.refreshWorkspace();
            },
          error: error => {
            if(error.includes("validation errors")) this.toastr.error(Labels.ValidationError)
            else this.toastr.error(Labels.UnExpectedErrorWSCreate)
          }
        });
      } else {
        this.resetEngagementTables(payload);
      }
    } else {
      this.workspaceService.saveWorkspace(payload).subscribe({
        next: () => {
            this.toastr.success(Labels.ModalCreateWSSuccess)
            this.refreshWorkspace()
          },
        error: error => {
          this.toastr.error(Labels.UnExpectedErrorWSUpdate);
        }
      });
    }
  }

  refreshWorkspace() {
    this.workspaceService.getWorkspaces().subscribe(
      workspaces => {
            
        workspaces.forEach(workspace => {
          workspace.modifiedOn = moment.utc(workspace.modifiedOn!=null?workspace.modifiedOn:workspace.createdOn).format('DD MMMM YYYY');  
          workspace.countryName = workspace.country.name;
        });
        this.workspaceService.workspaces.next(workspaces);
      }
    );
  }
  loadDdl() {
    this.commonService.getCountryUnordered().subscribe({
      next: country => {       
        this.country = country;
        this.loadddlCompletes.next(true);
      },
      error: error => {
        this.toastr.error(Labels.ServerErrorLoadingCountry)
      }
    });

    this.commonService.getIndustry().subscribe({
      next: industry => {
          this.industry = industry;
        },
      error: error => {
        this.toastr.error(Labels.ServerErrorLoadingCountry)
      }
    });
  }

  resetEngagementTables(formValues: any) {  
    this.questionMetadatService.resetEngagementTables().subscribe({
      next: () => {
          this.readinessScoreService.saveCalculatedReadinessScore().subscribe(); // Default ReadinessScore
          this.UpdateExistingEngagement(formValues);
        },
      error: error => {
        this.toastr.error(Labels.ResetFailed)
      }
    });
  }

  UpdateExistingEngagement(formValues: any) {   
    this.workspaceService.resetEngagement(this.guid, formValues).subscribe({
      next: () => {
          this.entitlementService.reset = true;
          this.toastr.success(Labels.ResetSuccessful)
          this.refreshWorkspace()
          this.router.navigateByUrl('/home');
        },
      error: error => {
        this.entitlementService.reset = false;
        this.toastr.error(Labels.ResetFailed)
      }
    });
  }

  markUserAsError(controlname: any) {
    if (controlname === 'partner') {
      this.myForm.form.controls.partnerName.setErrors({ error: true });
    } else {
      // seniormanager
      this.myForm.form.controls.seniorManagerName.setErrors({ error: true });
    }
  }

  closeIcon() {
    return IconoirXmark;
  }
}
