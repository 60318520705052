import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

export class LogVM {
  message: string;
  logLevel: LogLevel;
}

export class LogEntry {
  entryDate: Date = new Date();
  message = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate = true;

  buildLogString(): string {
    let rtnMsg = '';

    if (this.logWithDate) {
      rtnMsg += `${new Date()} - `;
    }

    rtnMsg += `Type: ${LogLevel[this.level]}`;
    rtnMsg += ` - Message: ${this.message}`;

    if (this.extraInfo.length) {
      rtnMsg += ` - Extra Info: ${this.formatParams(this.extraInfo)}`;
    }

    return rtnMsg;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some(p => typeof p === 'object')) {
      ret = '';
      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  level: LogLevel = LogLevel.All;
  logWithDate = true;
  private logApiUrl = environment.apiUrl + '/api/log/';
  constructor(private http: HttpClient, private settings: SettingsProvider) {}

  private shouldLog(level: LogLevel) {
    if (
      (level >= this.level && level !== LogLevel.Off) ||
      level === LogLevel.All
    ) {
      return true;
    }

    return false;
  }

  private writeToLog(msg: any, level: LogLevel, params: any[]) {
    const logEntry = new LogVM();
    logEntry.logLevel = LogLevel.Info;
    logEntry.message = msg;
    this.postlog(logEntry).subscribe();
  }

  postlog(log: LogVM): Observable<LogVM> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<LogVM>(this.logApiUrl, log, options);
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }
}
