import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Workspace } from './workspace';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private workspaceSelected: Workspace;
  public loggedInUser = new BehaviorSubject<string>('');
  public routeChanged = new BehaviorSubject<boolean>(true);
  public popupOpened = new BehaviorSubject<boolean>(false);
  public isPolicyAccepted = new BehaviorSubject<boolean>(false);
  public isFlagListCompleted = new BehaviorSubject<boolean>(false);
  workspaces = new BehaviorSubject<Workspace[]>([]);
  private readonly flagsListSubject = new BehaviorSubject<{ flag: string, value: boolean }[]>([]);
  public flagsList$ = this.flagsListSubject.asObservable();
  private workspaceUrl = environment.apiUrl + '/api/Engagement/';

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  setSelectedWorkspace(value: Workspace) {
    this.workspaceSelected = value;
    if (this.cookieService.check('workspaceSelected') === true) {
      this.cookieService.delete('workspaceSelected', '/');
    }
    this.cookieService.set('workspaceSelected', btoa(JSON.stringify(value)), undefined , '/');
  }
  setCurrentUser(value: string) {
    this.loggedInUser.next(value);
  }

  getCurrentUser() {
    return this.loggedInUser.asObservable();
  }
  getSelectedWorkspace(): Workspace {
    if (this.workspaceSelected === undefined) {
      if (this.cookieService.check('workspaceSelected') === true) {
      const wsSelected = this.cookieService.get('workspaceSelected');
      this.workspaceSelected = JSON.parse(atob(wsSelected));
      }
    }
    return this.workspaceSelected;
  }

  getWorkspaces(): Observable<Workspace[]> {
    return this.http.get<Workspace[]>(this.workspaceUrl).pipe(tap((data) => data));
  }

  getWorkspace(id): Observable<Workspace> {
    return this.http
      .get<Workspace>(this.workspaceUrl + id)
      .pipe(tap((data) => data), catchError(this.handleError<Workspace>('getWorkspace')));
  }

  getWorkspaceConfiguration(id?:string): Observable<string> {
    return this.http
      .get<string>(this.workspaceUrl + "engagementConfiguration/"+id)
      .pipe(tap((data) => data), catchError(this.handleError<string>('getWorkspaceConfiguration')));
  }

  updatetWorkspaceConfiguration(engagementConfiguration, guid) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if (guid) {
      const url = `${this.workspaceUrl}engagementConfiguration/${guid}`;
      const body = JSON.stringify(engagementConfiguration); 
      return this.http
        .put<string>(url, body, options);
    }
  }

  getFlagValue(flagName: string): boolean | undefined {
    const flag = this.flagsListSubject.getValue().find(f => f.flag === flagName);
    return flag ? flag.value : undefined;
  }
  

  saveWorkspace(workspace) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<Workspace>(this.workspaceUrl, workspace, options);
  }

  updateWorkspace(workspace, guid?) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if(guid){
      return this.http
      .put<Workspace>(this.workspaceUrl + guid, workspace, options);
    }
    else{
      return this.http
      .put<Workspace>(this.workspaceUrl + workspace.guid, workspace, options);
    }

  }

  deleteWorkspace(workspace) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .delete<Workspace>(this.workspaceUrl + workspace.guid, options)
      .pipe(catchError(this.handleError<Workspace>('saveWorkspace')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result);
    };
  }

  resetEngagement(oldEngId: any, workspace) {
    const url = this.workspaceUrl + 'ResetEngagement/' + oldEngId;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<Workspace>(url, workspace, options);
  }

  getPrivacyPolicy(engagementId): Observable<boolean> {
    const privacyPolicyUrl = environment.apiUrl + '/api/PrivacyPolicy/GetPrivacyPolicy/';
    return this.http.get<boolean>(`${privacyPolicyUrl}${engagementId}`).pipe(
      tap((data) => {
        this.isFlagListCompleted.next(false);
        this.getWorkspace(engagementId).subscribe((response: Workspace) => {
          if (response) {
            this.getWorkspaceConfiguration(response.guid).subscribe((configResponse: any) => {
              const flagsList = this.transformToFlagsList(configResponse);
              this.setFlagsList(flagsList);
            });
          }
        });
      }),
      catchError(this.handleError<boolean>('getPrivacyPolicy', false))
    );
  }
  
  public setFlagsList(flagsList: { flag: string, value: boolean }[]): void {
    this.flagsListSubject.next(flagsList);
    this.isFlagListCompleted.next(true);
  }

  public transformToFlagsList(configResponse: any): { flag: string, value: boolean }[] {
    const flagsList: { flag: string, value: boolean }[] = [];

    function processFeature(featureKey: string, featureValue: any, prefix = "") {
      const featureFlag = prefix ? `${prefix}_${featureKey.replace(/\s+/g, "_")}` : featureKey.replace(/\s+/g, "_");

      if (typeof featureValue === "boolean") {
        flagsList.push({ flag: featureFlag, value: featureValue });
        return;
      }

      if (featureValue && typeof featureValue === "object" && "enabled" in featureValue) {
        flagsList.push({ flag: featureFlag, value: featureValue.enabled });
      }

      if (featureValue && typeof featureValue === "object" && featureValue.subFeatures) {
        Object.keys(featureValue.subFeatures).forEach(subFeatureKey => {
          processFeature(subFeatureKey, featureValue.subFeatures[subFeatureKey], featureFlag);
        });
      }

      if (featureValue && typeof featureValue === "object" && featureValue.submodules) {
        Object.keys(featureValue.submodules).forEach(submoduleKey => {
          processFeature(submoduleKey, featureValue.submodules[submoduleKey], featureFlag);
        });
      }
    }

    Object.keys(configResponse).forEach(featureKey => {
      processFeature(featureKey, configResponse[featureKey]);
    });
    return flagsList;
  }
  
  

  acceptPrivacyPolicy(payload) {
    const privacyPolicyUrl = environment.apiUrl + '/api/PrivacyPolicy/SavePrivacyPolicy';
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<Workspace>(privacyPolicyUrl, payload, options);
  }
}
