import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WorkspaceService } from '../workspace/workspace.service';

@Injectable({
	providedIn: 'root'
})
export class EngagementGuard  {

	isPolicyAccepted:boolean=false;
	constructor(private workspaceService: WorkspaceService, private router: Router) {
		this.workspaceService.isPolicyAccepted.subscribe((res)=>{
			this.isPolicyAccepted=res;
		  })
	}

	canActivate(): boolean {
		let isValidEngagementId = false;
		if (
			this.workspaceService.getSelectedWorkspace() !== undefined &&
			this.workspaceService.getSelectedWorkspace() !== null
		) {
			isValidEngagementId = true;
			if(!this.isPolicyAccepted) {	      
				this.router.navigate(['/home']);
				this.workspaceService.isPolicyAccepted.next(false);
			}
		}

		if (isValidEngagementId) {
			return true;
		}
		alert('Invalid Engagement');
		this.router.navigate([ '/home' ]);
		return false;
	}
}
