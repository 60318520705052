<ng-container *ngIf="toasters && !isHide">
    <ng-container *ngFor="let toaster of toasters">
        <motif-toast 
            class="toaster-ct"
            [ngClass]="{'motif-toast-icon-resize': toaster.type === 'success' || toaster.type === 'error' || toaster.type === 'info'}"
            [type]="toaster.type" 
            [show]="true" 
            position="fixed" 
            placement="bottom"
            [duration]="duration">
            {{toaster.message}}
        </motif-toast>
    </ng-container>
</ng-container>