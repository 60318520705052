import { Injectable } from '@angular/core';
import { SessionService } from './session-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  sessionId;
  constructor(private sessionService: SessionService) { 
    this.sessionService.startSession();
    this.sessionService.sessionId.subscribe(item =>{
      this.sessionId = item
    })
  }

  canActivate(): boolean {
    if(this.sessionId){
      return true;
    }
  }
}
