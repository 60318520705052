import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, finalize, from, tap } from "rxjs";
import { WorkspaceService } from "../workspace/workspace.service";
import { Injectable } from "@angular/core";
import { SessionService } from "./session-service.service";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { CommonService } from "../_services/common.service";
import { environment } from "src/assets/client-config";
import * as CryptoJS from 'crypto-js';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  private isValidEngagementPresent = false;
  private engagementId = null;
  sessionActivityBuffer: boolean = true;
  sessionId = null;
  activeRequests: number = 0;
  httpOptions?: any;
 

  constructor(
    private workspaceService: WorkspaceService,
    private sessionService: SessionService, private router: Router, private msalService: MsalService, private commonService: CommonService
  ) {
    this.sessionService.sessionId.subscribe(res => {
      if (res) {
        this.sessionId = res;
      }
    })
  }

  intercept(
    req: any,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.activeRequests == 0) {
      //to show loader 
      this.commonService.isLoading.next(true);
    }
    //gets incremented on each API call
    this.activeRequests++;
    if (this.workspaceService.getSelectedWorkspace() !== null && this.workspaceService.getSelectedWorkspace() !== undefined) {
      this.isValidEngagementPresent = true;
      this.engagementId = this.workspaceService.getSelectedWorkspace().guid;
    }
    if (this.isValidEngagementPresent) {
      req = req.clone({
        setHeaders: {
          engagementid: this.engagementId,
          'access-control-expose-headers': 'Set-Cookie'
        },
        withCredentials: true
      });
    }
    else {
      req = req.clone({
        setHeaders: {
          'access-control-expose-headers': 'Set-Cookie'
        },
        withCredentials: true
      });

    }
    return from(this.handleAccess(req, next));
   
  }
  private async handleAccess(req: HttpRequest<any>, next: HttpHandler):
        Promise<HttpEvent<any>> {

           const request = (req.method!="GET" && !req.url.includes('sk'))? await this.getSecurityKey(req) : req;
            return next.handle(request).pipe(tap({
              next: (event: any) => {
                if (event.ok) {
                  //get decremented as each API call gets completed
                  this.activeRequests--;
                  if (this.activeRequests == 0){
                    //to hide loader after all the API calls are completed
                    this.commonService.isLoading.next(false);
                  }
                }
                if (!req.url.includes('assets') && !req.url.includes('session') && !req.url.includes('logout') && this.sessionActivityBuffer) {
                  this.sessionActivityBuffer = false;
                  //to set time buffer to avoid session activity call for every api call
                  setTimeout(() => {
                    this.sessionActivityBuffer = true;
                  }, 6000);
                }
              }, error: error => {
                //to hide loader on API error
                this.activeRequests--;
                this.commonService.isLoading.next(false);
                if (error.error.reason == "UserNotFound") {
                  this.router.navigateByUrl('/unauthorized');
                }
                else if (error.status == 401 && error.error.reason != "AppSessionNotFound" && !req.url.includes('logout')) {
                  this.sessionService.logoutSession();
                }
                else if (error.status == 401) {
                  this.msalService.logout();
                }
              }
              })).toPromise();
          
  }
  async getSecurityKey(req){
    var token:any =  await this.sessionService.getSecurityToken();
    token = CryptoJS.SHA256(token + environment.saltConstant)
    .toString(CryptoJS.enc.Hex);
    req = req.clone({
      setHeaders: {
        'rt': token
      },
      withCredentials: true
    });
  
    return req;
  }
}
