import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { SettingsProvider } from './app/settingsProvider';
import { SHARED_PROVIDERS } from './app/shared/shared-providers';
import { AppComponent } from './app/app.component';

const cconfig = require('./assets/client-config.ts');

if (cconfig.production) enableProdMode();

const loadAgGrid = async () => { LicenseManager.setLicenseKey(cconfig.aggridEnterpriseLicenseKey) };
if (cconfig.agGridEnterpriseMode) loadAgGrid();

export function init(settingsProvider: SettingsProvider) {
	return () => settingsProvider.loadConfig();
}

export function getAlertConfig(): TooltipConfig {
	return Object.assign(new TooltipConfig(), {
		triggers: 'hover'
	});
}

bootstrapApplication(AppComponent, {
	providers: SHARED_PROVIDERS
});
