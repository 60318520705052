<motif-modal-title>
  <h5 class="modal=title">{{title}}</h5>
  <button  id="close-btn" class="close-btn" motifIconButton aria-label="Close modal" size="medium" [motif-modal-close]="false">
    <motif-icon class="motif-modal-header-close-icon" size="20" [iconFunction]="closeIcon()"></motif-icon>
  </button>
</motif-modal-title>
<motif-modal-content>
  <form [formGroup]="newProjectForm">
      <motif-form-field>
          <label motifLabel class="select-label project-name-label">Project name (For confidentiality purposes, please avoid using the client’s company name as the project name.​) (required)</label>
          <input maxlength="100" formControlName="name" motifInput type="text"
          (input)="spaceValidator('name')" [validationInteraction]="'touched'" />
          <motif-error *ngIf="validator('name')">Required field</motif-error>
          <motif-error *ngIf="newProjectForm?.controls.name.hasError('spaceIssue')">{{spaceMsg}}</motif-error>
          <motif-error *ngIf="newProjectForm?.controls.name.hasError('patternIssue')">{{patternMsg}}</motif-error>
        </motif-form-field>
      <div class="motif-row">
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Client name (required)</label>
                  <input maxlength="100" formControlName="clientName" motifInput type="text" (input)="spaceValidator('clientName')" [validationInteraction]="'touched'" />
                  <motif-error *ngIf="validator('clientName')">Required field</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.clientName.hasError('spaceIssue')">{{spaceMsg}}</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.clientName.hasError('patternIssue')">{{patternMsg}}</motif-error>        
                  </motif-form-field>
          </div>
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Industry (required)</label>
                  <motif-select ngDefaultControl formControlName="industryId" aria-label="Aria Label" [validationInteraction]="'touched'">
                    <motif-option [value]="industry.id" *ngFor="let industry of industry">{{industry.name}}</motif-option>
                  </motif-select>
                  <motif-error *ngIf="validator('industryId')">
                      Required field
                  </motif-error>
              </motif-form-field>
          </div>
      </div>
      <div class="motif-row">
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Partner name (required)</label>
                  <input type="text" class="partner" formControlName="partnerName" (keyup)="loadPartners($event.target.value)" [motifTypeahead]="partners" motifInput [validationInteraction]="'touched'" [pattern]="pattern" />
                  <ng-container *ngIf="validator('partnerName')">
                    <motif-error *ngIf="validator('partnerName')">
                      Required field
                    </motif-error>
                  </ng-container>
                  <motif-error class="error-message" *ngIf="newProjectForm.controls.partnerName.hasError('pattern')">
                    Invalid user
                  </motif-error>
              </motif-form-field>
          </div>
          <div class="motif-col-sm-2">
            <motif-form-field>
              <label motifLabel class="select-label">Senior manager name (required)</label>
              <input [pattern]="pattern" (keyup)="loadSeniorManagers($event)" formControlName="seniorManagerName" [motifTypeahead]="seniorManagers" motifInput type="text" [validationInteraction]="'touched'" />
              <ng-container *ngIf="validator('seniorManagerName')">
                <motif-error *ngIf="validator('seniorManagerName')">
                  Required field
                </motif-error>
              </ng-container>
              <motif-error class="error-message" *ngIf="newProjectForm.controls.seniorManagerName.hasError('pattern')">
                  Invalid user
              </motif-error>
            </motif-form-field>
          </div>
      </div>
      <div class="motif-row">
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">City (required)</label>
                  <input [pattern]="'^[a-zA-Z ]*$'" maxlength="100" formControlName="city" motifInput type="text" (input)="spaceValidatorCity('city')" [validationInteraction]="'touched'"/>
                  <motif-error *ngIf="validator('city')">Required field</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.city.hasError('spaceIssue')">{{spaceMsg}}</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.city.hasError('patternIssue')">Only alphabets allowed</motif-error>        
              </motif-form-field>
          </div>
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Country (required)</label>
                  <motif-select ngDefaultControl formControlName="countryId" aria-label="Aria Label" [validationInteraction]="'touched'">
                    <motif-option [value]="country.id" *ngFor="let country of country">{{country.name}}</motif-option>
                  </motif-select>
                  <motif-error *ngIf="validator('countryId')">
                      Required field
                  </motif-error>
              </motif-form-field>
          </div>
      </div>
  </form>
</motif-modal-content>
<motif-modal-actions>
  <button motifButton motif-modal-close color="secondary" aria-label="Cancel"  size="medium" role="button">Cancel</button>
  <button (click)="saveWorkspace()" [disabled]="newProjectForm?.invalid || !newProjectForm.touched" motifButton color="primary" size="medium" [motif-modal-close]="true"
       role="button">{{saveBtnName}}</button>
</motif-modal-actions>
