import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './readiness/dashboard/dashboard.component';
import { ReportsComponent } from './readiness/reports/reports.component';
import { AuthGuard } from './session_management/auth.guard';
import { EngagementGuard } from './_guard/engagement.guard';
import { CreateWorkspaceComponent } from './workspace/create-workspace/create-workspace.component';
import { FinancialplanningComponent } from './readiness/financialplanning/financialplanning.component';
import { QuestionairrelibraryComponent } from './readiness/questionairrelibrary/questionairrelibrary.component';
import { NotesComponent } from './readiness/notes/notes.component';
import { QuestionComponent } from './readiness/questionnaireclientview/question/question.component';
import { OverviewApproachComponent } from './readiness/reports/overview-approach/overview-approach.component';
import { SummaryKeyfindingsComponent } from './readiness/reports/summary-keyfindings/summary-keyfindings.component';
import { KeyActivitiesComponent } from './readiness/reports/key-activities/key-activities.component';
import { QuestionSummaryComponent } from './readiness/reports/question-summary/question-summary.component';
import { TemplateSelectionComponent } from './readiness/reports/template-selection/template-selection.component';
import { EntitlementsAuthorizationGuard } from './_guard/entitlementsauthorization.guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { LogoutComponent } from './logout/logout.component';
// tslint:disable-next-line:max-line-length
import { QuestionnaireLibraryMasterComponent } from './readiness/questionairrelibrary/questionnairelibrary-master/questionnairelibrary-master.component';
// tslint:disable-next-line:max-line-length
import { FunctionalareareadinessComponent } from './readiness/dashboard/functionalareareadiness/functionalareareadiness.component';
import { HeatmapComponent } from './readiness/dashboard/heatmap/heatmap.component';
import { QuestionUploadComponent } from './question-upload/question-upload.component';
import { QuestionUploadGuard } from './question-upload/guard/question-upload.guard';
export const appRoutes: Routes = [
	{ path: 'home', component: HomeComponent, canActivate: [ AuthGuard ] },
	{
		path: 'user',
		loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
		canActivate: [ AuthGuard, EntitlementsAuthorizationGuard ]
	},
	{
		path:'readiness',
		loadChildren: () => import('./readiness/readiness.module').then(m => m.ReadinessModule),
    	canActivate: [AuthGuard],
	},

	// {
	// 	path: 'readiness/dashboard',
	// 	component: DashboardComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/dashboard/functionalareareadiness',
	// 	component: FunctionalareareadinessComponent,
	// 	canDeactivate: [ 'canDeactivate' ],
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/dashboard/heatmap',
	// 	component: HeatmapComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/report',
	// 	component: ReportsComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/questionbuilderlibrary',
	// 	component: QuestionnaireSetupHeaderComponent,
	// 	// canDeactivate: ['canDeactivate'],
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/questionbuilderlibrary/questionlibrary',
	// 	component: QuestionnaireLibraryMasterComponent,
	// 	// canDeactivate: ['canDeactivate'],
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/questionbuilderlibrary/questionnairesetup',
	// 	component: QuestionairrelibraryComponent,
	// 	canDeactivate: [ 'canDeactivate' ],
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/financialplan',
	// 	component: FinancialplanningComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/question/:id',
	// 	component: QuestionComponent,
	// 	canDeactivate: [ 'canDeactivate' ],
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/notes',
	// 	component: NotesComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/report/overview',
	// 	component: OverviewApproachComponent,
	// 	canDeactivate: [ 'canDeactivate' ],
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/report/summary',
	// 	component: SummaryKeyfindingsComponent,
	// 	canDeactivate: [ 'canDeactivate' ],
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/report/activities',
	// 	component: KeyActivitiesComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/report/questionsummary',
	// 	component: QuestionSummaryComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	// {
	// 	path: 'readiness/report/templateSelection',
	// 	component: TemplateSelectionComponent,
	// 	canActivate: [ AuthGuard, EngagementGuard ]
	// },
	{
		path: 'unauthorized',
		component: UnauthorizedComponent,
		canActivate: [ AuthGuard ]
	},
	{
		path: 'notfound',
		component: NotfoundComponent,
		canActivate: [ AuthGuard ]
	},
	{
		path: 'logout',
		component: LogoutComponent
	},
	{
		path: 'question-upload',
		component: QuestionUploadComponent,
		canActivate: [ QuestionUploadGuard ]
	},
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: '**', redirectTo: 'notfound', pathMatch: 'full' }
];

