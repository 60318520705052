import { Component } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { QuestionUploadService } from "./services/question-upload.service";
import { ToasterService } from "../shared/toast/toaster.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: 'app-question-upload',
    templateUrl: './question-upload.component.html',
    styleUrls: ['./question-upload.component.scss']
})
export class QuestionUploadComponent {
   
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    typeError: boolean = false;
    file: File;
    isLoading: boolean = false;
    form: FormGroup = new FormGroup({
        files: new FormControl({ value: null, disabled: false }, [
          Validators.required,
        ]),
      });
    constructor(private questionUploadService: QuestionUploadService, private toastr: ToasterService){
        this.questionUploadService.isLoading.subscribe(item=>{
            this.isLoading = item;
        })
    }
    ngOnInit(): void {
        this.form.controls.files.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
            console.log(value)
          this.handleFileInput(value)
        });
    }
    ngModelQueue: any;
   
    handleFileInput(files) {
        const arr = files?.name.split('.');
        const type = arr[arr?.length-1];
        if(type !='xlsx'){
            this.typeError = true;
        }
        else{
            this.typeError = false;
            this.file = files;
        }
    }

    uploadFile(){
        const formData = new FormData();
        formData.append('fileData', this.file);
        this.questionUploadService.uploadQuestionFile(formData).subscribe({
            next: result => {
                this.toastr.success('File uploaded successfully')
            },
            error: error=>{
            this.toastr.error('File upload failed')
        }
        })
    }

    clearFileSelection(){
        this.form.value.files = null;
        this.form.reset();
        this.file = null
    }

    resetProject(e){
        e.target.value = null;
    }
    
    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}