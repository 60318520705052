<header motif-header class="layout-header" appHeaderName="">
  <img *ngIf="!isUnauthorized" [routerLink]="['/home']" motif-header-logo class="logo" alt="Application logo" src="./assets/icons/eylogo.svg" position="left">
  <img *ngIf="isUnauthorized" motif-header-logo class="logo" alt="Application logo" src="./assets/icons/eylogo.svg" position="left">
  <span *ngIf="!isUnauthorized" motif-header-title class="p-l-2 headertitle" position="left"
    [routerLink]="['/home']" >IPO Enabler</span>
  <span *ngIf="isUnauthorized" motif-header-title class="p-l-10 headertitle" position="left">IPO Enabler</span> 
  <span *ngIf="env" motif-header-title class="p-l-10 envname" position="left">({{env.toUpperCase()}})</span>
  <span motif-header-title  class="p-l-10 headertitle" position="left">
<motif-tab-navigation variant="default" orientation="horizontal">
  <button [disabled]="isUnauthorized"
    motifButton 
    [ngClass]="{'motif-active' : currentOption === 1 }"
    [routerLink]="['/home']"
    motif-tooltip="See all your current projects in one place"
    (mouseout)="removeTooltip()"
  >
   <span class="header-text"> Workspace listing</span>
  </button>
  <button [disabled]="isUnauthorized" *ngIf="hasAddWorkspaceEntitlement(['AU'])"
    motifButton 
    [ngClass]="{'motif-active' : currentOption === 2 }"
    [routerLink]="['/user']"
    motif-tooltip="View authorised users and their access rights"
    (mouseout)="removeTooltip()"
  >
  <span class="header-text"> User management </span>
  </button>

<div motifDropdown
      placement="bottom-left"
      scrollStrategy="reposition"
      [autoClose]="false"
      [closeOnBackdropClick]="true"
      >
      <button [disabled]="isUnauthorized" motifButton aria-label="Dropdown toggle example" motifDropdownToggle id="motifExampleDropdown">
      <span class="header-text">  Quick links </span> 
      <motif-icon  [iconFunction]=arrowIcon()></motif-icon>
      </button>
      <div   (click)="toggleAddIcon($event)"  motifDropdownMenu aria-labelledby="motifExampleDropdown">
          <button (click)="navigateQuickLink('India FAAS SharePoint')" class="menu-border"  motifDropdownItem>
              <span class="header-text">India FAAS SharePoint</span>
          </button>
          <button (click)="navigateQuickLink('Americas FAAS Wiki')"  class="menu-border" motifDropdownItem>
              <span class="header-text">Americas FAAS Wiki</span>
          </button>
          <button (click)="navigateQuickLink('Global FAAS Sharepoint')"  class="menu-border" motifDropdownItem>
            <span class="header-text">Global FAAS Sharepoint</span>
        </button>
        <button (click)="navigateQuickLink('Key EY Contacts')"  class="menu-border" motifDropdownItem>
          <span class="header-text">Key EY Contacts</span>
      </button>
      <button (click)="navigateQuickLink('Americas IPO Sharepoint')"  class="menu-border" motifDropdownItem>
        <span class="header-text">Americas IPO Sharepoint</span>
    </button>
    <button class="menu-border" motifDropdownItem>
      <span class="header-text">Templates
        <motif-icon *ngIf="!isAddIcon" class="add-icon" id="template" (click)="removeTooltipDropdown()" (mouseout)="removeTooltipDropdown()"  motif-tooltip="Click to see the templates available"  [iconFunction]=arrowIcon()></motif-icon>
        <motif-icon *ngIf="isAddIcon" class="up-icon" [iconFunction]=uparrowIcon()></motif-icon>
      </span>
    </button>
    <button (click)="navigateQuickLink('IPOE readiness assessment report general template')"  *ngIf="isAddIcon" class="menu-border" motifDropdownItem>
      <span class="header-text">IPOE readiness assessment report general template</span>
  </button>
      </div>
  </div>
  </motif-tab-navigation>
</span>
<span class="profile" position="right">
    <div motifDropdown
      placement="bottom-right"
      scrollStrategy="reposition"
      [autoClose]="true"
      [closeOnBackdropClick]="true"
      >
      <button [disabled]="isUnauthorized" [ngClass]="isUnauthorized ? 'disabled' : ''" motifIconButton aria-label="Dropdown toggle example"
       motifDropdownToggle id="motifExampleInfoDropdown">
        <motif-icon class="info-i" [iconFunction]=infoIcon()></motif-icon>
      </button>
      <div motifDropdownMenu aria-labelledby="motifExampleInfoDropdown">
        <button class="menu-item" (click)="about()" motifDropdownItem>
            <motif-icon  [iconFunction]=aboutIcon()></motif-icon>
            <span class="profile-text">About IPO Enabler</span>
        </button>
        <button class="menu-item" (click)="openUserGuide()" motifDropdownItem>
            <motif-icon  [iconFunction]=userGuideIcon()></motif-icon>
            <span class="profile-text">User Guide</span>
        </button>
        <button class="menu-item" (click)="showPrivacyPolicy()" motifDropdownItem>
          <motif-icon [iconFunction]=privacyIcon()></motif-icon>
          <span class="profile-text">Privacy Policy</span>
      </button>
    </div>
    </div>
      <div motifDropdown
      placement="bottom-right"
      scrollStrategy="reposition"
      [autoClose]="true"
      [closeOnBackdropClick]="true"
      >
      <button [disabled]="isUnauthorized" motifIconButton aria-label="Dropdown toggle example"
       motifDropdownToggle id="motifExampleDropdown">
        <motif-avatar [ngClass]="isUnauthorized ? 'disabled avatar' : 'avatar'" *ngIf="!isProfilePic"
        [src]="profilePic" size="small" [userName]="name"></motif-avatar>
      <motif-avatar [ngClass]="isUnauthorized ? 'disabled avatar' : 'avatar'" *ngIf="isProfilePic"
        [src]="profilePic" size="small" [userName]="name"></motif-avatar>
      </button>
      <div motifDropdownMenu aria-labelledby="motifExampleDropdown">
        <button class="menu-item"  motifDropdownItem>
            <motif-icon [iconFunction]=profileIcon()></motif-icon>
            <span class="profile-text">{{name}}</span>
        </button>

        <div class="menu-item"  motifDropdownItem>
          <div class="last-login-text profile-text"><span>Last login activity</span><br/>
          {{lastLoginActivity}}</div>
        </div>

          
        <button class="menu-item" (click)="logout()" motifDropdownItem>
          <motif-icon  [iconFunction]=logoutIcon()></motif-icon>
          <span class="profile-text">Logout</span>
      </button>
      </div>
  </div>
</span>
</header>
