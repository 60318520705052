export const environment = {
  production: true,
  "agGridEnterpriseMode": true,
  "aggridEnterpriseLicenseKey": "EYGS_LLP_IPO_Enabler_3Devs31_July_2020__MTU5NjE1MDAwMDAwMA==66569f1cc49828024bf9b5bafaeca037",
  apiUrl: "https://ipoe-qa-api.ey.com",
  "onPremises": true,
  "envName": "QA",
  "appVersion": "5.3.0",
  "appReleaseYear": "2025",
  "hideBenchMarking": false,
  loginUrl:'/api/auth/setsession',
  renewSession: '/api/auth/renewsession',
  updateSession:'/api/session/sessionactivity',
  logout:'/api/auth/logout',
  saltConstant:'Xyc_Zm?Iv6QK)mAVoWzIl{s!s#_{W0g',
  msalConfig: {
    clientId: "3595387c-0ab6-49f5-942e-c3b9dfab0b05",
    authority: `https://login.microsoftonline.com/ey.com`,
    redirectUri: 'https://ipoe-qa.ey.com',
    postLogoutRedirectUri: 'https://ipoe-qa.ey.com/logout',
    profileUrl: 'https://graph.microsoft-ppe.com/v1.0/me',
    apiClientId: '23fdddb7-082f-4a22-8a61-4734c20e191f'
  }
};
