import { Component, Inject } from '@angular/core';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
import { IconoirXmark } from '@ey-xd/motif-icon';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  title: string;
  content: any; 
  cancelBtnName: string;
  isDeleteButton = false;

  constructor(
    public modal: MotifModal,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
  ) {   
    
  }

  closeIcon() {
    return IconoirXmark;
  }

}
