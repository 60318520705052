import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MOTIF_MODAL_DATA, MotifModule } from '@ey-xd/ng-motif';
import { IconoirXmark } from '@ey-xd/motif-icon';
import { Labels } from '../locale/en/labels.en';
import { WorkspaceService } from '../workspace/workspace.service';
import { ToasterService } from '../shared/toast/toaster.service';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [MotifModule, CommonModule, ReactiveFormsModule],
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})

export class PrivacyPolicyComponent implements OnInit {

  checked: boolean = false;
  title: string;
  saveBtnName: string;
  privacyPolicyForm: UntypedFormGroup;
  isReadonly: boolean;
  engagementId;

  constructor(@Inject(MOTIF_MODAL_DATA) data: any,
  private fb: FormBuilder,
  private workspaceService: WorkspaceService,
  private toastr: ToasterService
  ) {
    this.title = data.initialState.title;
    this.saveBtnName = data.initialState.saveBtnName;
    this.isReadonly = data.initialState.isReadOnly;
    this.engagementId = data.initialState?.engagementId;
  }

  ngOnInit(): void {
    this.generateForm();
  }

  generateForm() {
    this.privacyPolicyForm = this.fb.group({
      policy: [false, [Validators.required]]
    })
  }

  acceptPolicy() {
    this.workspaceService.isPolicyAccepted.next(true);
    const payload = {
      engagementId: this.engagementId,
      isViewed: true
    }

    this.workspaceService.acceptPrivacyPolicy(payload).subscribe({
      next: (result) => {
      this.toastr.success(Labels.privacyPolicySuccess)
    }, error: error => {
      this.toastr.error(Labels.privacyPolicyError)
    }
    })
  }

  onChange(value) {
    this.checked = value;
  }

  closeIcon() {
    return IconoirXmark;
  }
}
