import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntitlementService } from '../_services/entitlementService';

@Injectable({
  providedIn: 'root'
})
export class EntitlementsAuthorizationGuard  {
  userEngagementEntitlement: any;
  constructor(
    private entitlementService: EntitlementService,
    private router: Router,
  ) {
    this.userEngagementEntitlement = this.entitlementService.getRolesEntitlement();
  }

  canActivate(): Promise<boolean> {
    let entitled = false;

    return new Promise(resolve => {
      this.entitlementService
        .getUserRolesEntitlement()
        .subscribe({next: userEngagementEntitlement => {
          this.userEngagementEntitlement = userEngagementEntitlement;

          const entitlementsArray = ['AU', 'EU', 'DU'];

          if (
            this.userEngagementEntitlement === undefined ||
            this.userEngagementEntitlement.length === 0
          ) {
            this.router.navigate(['/unauthorized']);
          } else {
            this.userEngagementEntitlement.forEach(element => {
              element.userEntitlementRoleVM.forEach(entitlement => {
                if (entitlementsArray.indexOf(entitlement.code) > -1) {
                  entitled = true;
                  resolve(true);
                }
              });
            });
            if (!entitled) {
              this.router.navigate(['/unauthorized']);
            } else {
              resolve(true);
            }

            resolve(false);
          }
          resolve(false);
        }});
    });
  }
}
