import { Component, OnInit } from '@angular/core';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigationStart, Router, Event as NavigationEvent, } from '@angular/router';
import { CommonService } from './_services/common.service';

export function getAlertConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    container: 'body',
    delay: 400
  });
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  onPremises: boolean;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  redirectURL: any;
  isIframe = false;
  isLogin: boolean = false;
  isLoading: boolean = false;
  constructor(private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService, private router: Router, private commonService: CommonService) {
    this.commonService.isLoading.subscribe({next: result => {
      this.isLoading = result;
    }})
    this.setLoginDisplay();
    this.isIframe = window !== window.parent && !window.opener;
   
    this.router.events.subscribe({next: (event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('/logout')) {
          this.isLogin = false;
        } else {
          this.isLogin = true;
        }
      }
    }});
  }
  ngOnInit() {

    //to set active account
    this.authService.handleRedirectObservable().subscribe();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS),
      )
      .subscribe({next: (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      }});
      //to set login completed flag once all the interactions are complete
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe({next: () => {
        this.setLoginDisplay();
      }});
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
}
