import { Component, Inject } from '@angular/core';
import { MOTIF_MODAL_DATA, MotifModal, MotifModalModule, MotifModule } from '@ey-xd/ng-motif';
import { IconoirXmark } from '@ey-xd/motif-icon';
import { environment } from 'src/assets/client-config';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [MotifModule, MotifModalModule],
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})

export class AboutComponent {
  title: string;
  content: any; 
  cancelBtnName: string;
  isDeleteButton = false;
  env = environment;

  constructor(
    public modal: MotifModal,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
  ) {   
    
  }

  closeIcon() {
    return IconoirXmark;
  }

}
