import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { WorkspaceService } from '../workspace/workspace.service';
import { Workspace } from '../workspace/workspace';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { CreateWorkspaceComponent } from '../workspace/create-workspace/create-workspace.component';
import { QuickLinksService } from '../_services/quickLinks.service';
import { QuickLink } from '../models/quickLink.model';
import { UsermanagementService } from '../_services/usermanagement.service';
import { Labels } from '../locale/en/labels.en';
import { EntitlementService } from '../_services/entitlementService';
import { ModalSizes, MotifModal, MotifModalRef } from '@ey-xd/ng-motif';
import moment from 'moment';
import { DeletePopupComponent } from '../workspace/delete-workspace/delete-workspace.component';
import { SessionService } from '../session_management/session-service.service';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { ToasterService } from '../shared/toast/toaster.service';
import { MOTIF_ICONS } from '../constants/motif-iconts.constant';
@Component({
  selector: 'app-workspace-list',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  modalRef: BsModalRef;
  workspaces: Workspace[] = [];
  quickLinks: QuickLink[] = [];
  errorMessage: string;
  delworkspace: Workspace;
  subscriptions: Subscription[] = [];
  alerts: any[] = [];
  userEngagementEntitlement: any;
  navWidth = 0;
  showchild = false;
  templateName = Labels.JapanTemplateTitle;
  collapseTitle = Labels.CollpaseTemplateTitle;
  expandTitle = Labels.ExpandTemplateTitle;
  pptText = Labels.JapanPPTTitle;
  clickToSee = Labels.ClickToSeeTitle;
  motifModalRef: MotifModalRef<any>;
  lastLoginActivity:string = moment.utc(new Date()).format('DD MMMM YYYY');
  currentUser: any;
  workspacesData: Workspace[] = [];
  selectedProjectsFilter = 'all';
  selectedProjectsMessage = '';
  isWorkspaceLoaded = false;

  constructor(
    private workspaceService: WorkspaceService,
    private router: Router,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private quickLinksService: QuickLinksService,
    private usermanagementService: UsermanagementService,
    private entitlementService: EntitlementService,
    private motifModal: MotifModal,
    private sessionService: SessionService,
    private toastr: ToasterService,
  ) {
    if (this.entitlementService.reset) {
      this.entitlementService.refreshUserRolesEntitlement();
      this.DisplayMessage(Labels.ResetSuccessful, 'success');
      this.entitlementService.reset = false;
    }
    this.workspaceService.loggedInUser.subscribe(result=>this.currentUser=result);   
    this.workspaceService.workspaces.subscribe(result=>{
      if(result){
        this.workspaces = result;
        this.workspacesData = result;
        this.getProjects(this.selectedProjectsFilter);
      }
    }) 
    
  }
  ngOnInit(): void {   
    this.sessionService.sessionId.subscribe(result=>{     
      if(result){
        this.getQuickLinks();
        this.getAllWorkspace();
      }
    })
  }

  setSelectedWorkspace(workspace) { 
    this.workspaceService.setSelectedWorkspace(workspace);
    this.workspaceService.getPrivacyPolicy(workspace.id).subscribe((result) => {
      if(result) {      
        this.workspaceService.isPolicyAccepted.next(true);
        this.router.navigate(['/readiness/questionbuilderlibrary']);
      } else {
        this.openPrivacyPolicyModal(workspace.id);
      }
    }) 
  }

  OpenCreateWorkspaceModel(): void {
    const elem:any = document.querySelectorAll('.add-project');
    elem.forEach(element => {
      element.blur();
    });
    this.motifModalRef = this.motifModal.open(CreateWorkspaceComponent, {
      data: {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title: Labels.ModalTitleAddWS,
        guid: '',
        saveBtnName: Labels.ModalSaveBtnName
      }
      },
      size: ModalSizes.XL,
    });
    this.motifModalRef.addPanelClass("resetproject")
  }

  SubscribePopUpEvent() {   
    const _combine = combineLatest([this.modalService.onHide]).subscribe(() =>
      this.changeDetection.markForCheck()
    );
    
   this.subscriptions.push(
      this.modalService.onHide.subscribe((refresh: string) => {
        if (refresh === 'save') {
          this.getAllWorkspace();
          this.DisplayMessage(
            '<strong>' +
              this.modalRef.content.workSpaceName +
              '</strong> ' +
              Labels.ModalCreateWSSuccess,
            'success'
          );
        } else if (refresh === 'update') {
          this.getAllWorkspace();
          this.DisplayMessage(
            '<strong>' +
              this.modalRef.content.workSpaceName +
              '</strong> ' +
              Labels.ModalUpdateWSSuccess,
            'success'
          );
        } else if (refresh === 'confirm') {
          this.DeleteWorkspace(this.delworkspace);
        } else if (refresh === 'decline') {
          this.delworkspace = null;
        }
        this.UnsubscribePopUpEvent();
      })
    );
    this.subscriptions.push(_combine);
  }

  UnsubscribePopUpEvent() {   
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  getProjects(value) {
    let searchValue: string | boolean;
    if (value === 'active') {
      searchValue = true;
      this.selectedProjectsMessage = Labels.activeProjects;
    } else if (value === 'inactive') {
      searchValue = false;
      this.selectedProjectsMessage = Labels.inactiveProjects;
    } else {
      this.selectedProjectsMessage = "";
      searchValue = value;
    }
    
    this.selectedProjectsFilter = value;

    if(searchValue != 'all') {
      this.workspaces = this.workspacesData.filter((workspace) => {
        return workspace.isProvisioned == searchValue;
      });
    } else {
      this.workspaces = this.workspacesData;
    }
  }
  
  iconFunction(icon) {
    return MOTIF_ICONS[icon];
  }

  OpenUpdateWorkspaceModel(guid: string): void {
    this.motifModalRef = this.motifModal.open(CreateWorkspaceComponent, {
      data: {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title: Labels.ModalTitleUpdateWS,
        guid: guid,
        saveBtnName: Labels.SaveBtnName
      }
      },
      size: ModalSizes.XL,
    });
    this.motifModalRef.addPanelClass("resetproject")
  }

  DeleteWorkspace(workspace: Workspace): void {
    this.workspaceService.deleteWorkspace(workspace).subscribe({
      next: () => {
      this.workspaces.splice(this.workspaces.indexOf(workspace), 1);
      this.workspacesData = this.workspaces;
      this.getProjects(this.selectedProjectsFilter);

      this.DisplayMessage(
        '<strong>' +
        this.delworkspace.name +
        '</strong> ' +
        Labels.ModalDeleteWSSuccess,
        'success'
      );
      this.delworkspace = null;
      },
      error: () => {
        this.toastr.error(Labels.ModalDeleteWSSuccess)
      }

    });
  }

  OpenDeleteConfirmModal(workspace: Workspace) {
    this.SubscribePopUpEvent();
    this.delworkspace = workspace;

    this.motifModalRef = this.motifModal.open(DeletePopupComponent, {
      data: {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title: Labels.ModalTitleDeleteWS,
        content:'<p>Are you sure you want to delete this project?</p><p>Once deleted retrieval is not possible.</p>',
        confirmBtnName: Labels.ModalDeleteConfirmBtnName,
        cancelBtnName : Labels.ModalDeleteCancelBtnName,
        workspace: workspace
      }
      },
      size: ModalSizes.XL,
    });
  }

  getAllWorkspace(): void {
    this.workspaceService.getWorkspaces().subscribe({
      next: workspaces => {
          this.workspaces = workspaces;
          this.isWorkspaceLoaded = true;
          this.workspacesData = workspaces;
          this.workspaces.forEach(workspace => {
            workspace.modifiedOn = moment(workspace.modifiedOn != null ? workspace.modifiedOn : workspace.createdOn).format('DD MMMM YYYY');
            workspace.countryName = workspace.country.name;
          });
          this.workspaceService.workspaces.next(this.workspaces);
        },
      error: () => {
      this.toastr.error(Labels.UnExpectedErrorWSLoad)
    }
    });
  }

  getQuickLinks(): void {
    this.quickLinksService.getQuickLinks().subscribe({
      next: quickLinks => {       
        this.quickLinks = quickLinks;
      },
      error: () => {
        this.toastr.error(Labels.UnExpectedErrorQuickLinksLoad)
      }
    });
  }

  openPrivacyPolicyModal(engagementId) {
    this.motifModalRef = this.motifModal.open(PrivacyPolicyComponent, {
      id: "privacyPolicyComponent",
      data: {
        initialState: {
          title: Labels.modalTitle,
          saveBtnName: Labels.modalBtnName,
          isReadOnly: false,
          engagementId: engagementId
        },
        backdrop: 'static',
        keyboard: false,
      },
      size: ModalSizes.L,
      panelClass:'privacypolicy'
    });

    this.motifModalRef.afterClosed().subscribe(result => {
      if(result) {
        this.router.navigate(['/readiness/questionbuilderlibrary']);
      }
    })
  }

  DisplayMessage(message: string, type: string) {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: type === 'danger' ? 0 : 5000
    });
  }

  getUserEngagementEntitlements() {
    this.usermanagementService.getUserRolesEntitlement().subscribe({
      next: userEngagementEntitlement => {
        this.userEngagementEntitlement = userEngagementEntitlement;
      },
      error: () => {
        this.toastr.error(Labels.ServerErrorUserEntitlements);
      }
    });
  }

  hasWorkspaceEntitlement(id, entitlementType: string): boolean {
    this.userEngagementEntitlement = this.entitlementService.getRolesEntitlement();

    return this.checkUserEngagementEntitlement(
      this.userEngagementEntitlement,
      id,
      entitlementType
    );
  }

  checkUserEngagementEntitlement(
    userEngagementEntitlement,
    id,
    entitlementType
  ): boolean {  
    let entitled = false;
    if (userEngagementEntitlement !== undefined) {
      userEngagementEntitlement.forEach(element => {
        if (element.sa) {
          entitled = true;
          return entitled;
        }
        if (element.engagementId === id) {
          element.userEntitlementRoleVM.forEach(entitlement => {
            if (entitlement.code === entitlementType) {
              entitled = true;
              return entitled;
            }
          });
        }
      });
    }
    return entitled;
  }

  hasAddWorkspaceEntitlement(entitlementsArray: string[]): boolean {
    let entitled = false;
    this.userEngagementEntitlement = this.entitlementService.getRolesEntitlement();

    if (this.userEngagementEntitlement !== undefined) {
      this.userEngagementEntitlement.forEach(element => {
        element.userEntitlementRoleVM.forEach(entitlement => {
          if (entitlementsArray.indexOf(entitlement.code) > -1) {
            entitled = true;
            return entitled;
          }
        });
      });
    }
    return entitled;
  }

  isUnAuthorisedUser() {   
    const entitlement = this.entitlementService.getRolesEntitlement();
    if (entitlement !== undefined && entitlement.length === 0) {
      return true;
    }

    return false;
  }

  
  openSideNav() {
    this.navWidth = 250;
  }

  closeSideNav() {
    this.navWidth = 0;
    this.showchild = false;
  }
}
