import { Component, OnInit, Input } from '@angular/core';
import { WorkspaceService } from '../workspace/workspace.service';
import { Workspace } from '../workspace/workspace';
import { Router, NavigationEnd } from '@angular/router';
import { EntitlementService } from '../_services/entitlementService';
import { ModalSizes, MotifModal, MotifModalRef, VerticalNavigationMenuItemModel } from '@ey-xd/ng-motif';
import { UsermanagementService } from '../_services/usermanagement.service';
import { AboutComponent } from '../shared/about/about.component';
import { QuickLinksService } from '../_services/quickLinks.service';
import { QuickLink } from '../models/quickLink.model';
import { environment } from 'src/assets/client-config';
import { SessionService } from '../session_management/session-service.service';
import { MsalService } from '@azure/msal-angular';
import { ToasterService } from '../shared/toast/toaster.service';
import { Labels } from '../locale/en/labels.en';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { MotifActionIcInfoOutline24px, IconoirBookLock, IconoirLogOut, IconoirUser, IconoirPrivacyPolicy, MotifHardwareIcKeyboardArrowDown24px, MotifHardwareIcKeyboardArrowUp24px, MotifContentIcAdd24px, IconoirMinus, IconoirInfoCircle,IconoirBookStack } from '@ey-xd/motif-icon';
import moment from 'moment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  currentOption=1;
  isOpened:boolean=false;
  @Input() showMenu: boolean = false;
  quickLinks: QuickLink[] = [];
  useContextMenu: boolean = true;
  model: any = {};
  readonly GUESTNAME: string = 'Guest User';
  username: string = this.GUESTNAME;
  name: string = this.GUESTNAME;
  isCollapsed = true;
  currentUrl = '';
  userEngagementEntitlement: any;
  isProduction = false;
  env: any;
  onPremises: boolean;
  showTemplates = false;
  public modalRef?: MotifModalRef<any>;
  profilePic: any;
  isProfilePic: boolean = false;
  isUnauthorized:boolean=false;
  isAddIcon:boolean=false;
  user: any;
  lastLoginActivity:string = moment.utc(new Date()).format('MMMM DD, YYYY');
  menu = [
    {
      label: "See all your current projects in one place",
      displayLabel: "Workspace Listing",
      routerLink: ["/home"],
      exact: true,
      icon: "/assets/icons/action/ic_dashboard_24px.svg"
    },
    {
      label: "View authorised users and their access rights",
      displayLabel: "User Management",
      routerLink: ["/user"],
      exact: true,
      icon: "/assets/icons/action/ic_account_circle_24px.svg",
    },
    {
      label: "Quicklinks",
      displayLabel: "Quick Links",
      icon: "/assets/icons/action/ic_launch_24px.svg",
      exact: false,
      subMenu: new Array<VerticalNavigationMenuItemModel>(),
    }
  ];

  constructor(
    public authService: MsalService,
    private userService: UsermanagementService,
    private workspaceService: WorkspaceService,
    public router: Router,
    private entitlementService: EntitlementService,
    public motifModal: MotifModal,
    private quickLinksService: QuickLinksService,
    private sessionService: SessionService,
    private toastr: ToasterService
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url === e.urlAfterRedirects ? e.url : e.urlAfterRedirects;
        if(this.currentUrl=='/unauthorized'){
          this.isUnauthorized=true;
        }
        if (e.url.includes('question-upload')) {
          this.getProfile();
          this.getQuickLinks();
        }
        if(this.currentUrl=='/home'){
          this.currentOption=1;
        }
        if(this.currentUrl=='/user'){
          this.currentOption=2;
        }
      }
    });
    this.isProduction = environment.production === undefined || environment.production;    
    this.env = environment.envName;   
    this.onPremises = environment.onPremises;
    this.sessionService.sessionId.subscribe(result => {
      if (result) {
        this.getProfile();
        this.getQuickLinks();
      }
    })
  }

  ngOnInit() {   
    this.name = this.authService.instance.getActiveAccount().name;
  }

  hasAddWorkspaceEntitlement(entitlementsArray: string[]): boolean {
    let entitled = false;
    this.userEngagementEntitlement = this.entitlementService.getRolesEntitlement();
    if (this.userEngagementEntitlement !== undefined) {
      this.userEngagementEntitlement.forEach(element => {
        element.userEntitlementRoleVM.forEach(entitlement => {
          if (entitlementsArray.indexOf(entitlement.code) > -1) {
            entitled = true;
            return entitled;
          }
        });
      });
    }
    return entitled;
  }

  removeTooltip() {
    const elem1: any = document.querySelectorAll('.motif-button');
    elem1.forEach(el => {
      el.blur();
    }); 
  }

  removeTooltipDropdown() {
    const elem1: any = document.querySelectorAll('#template');
    elem1.forEach(el => {
      el.blur();
    }); 
  }

  infoOutlineIcon() {
    return MotifActionIcInfoOutline24px;
  }

  privacyIcon(){
    return IconoirBookLock;
  }

  profileIcon(){
    return IconoirUser;
  }

  arrowIcon(){
    return MotifHardwareIcKeyboardArrowDown24px;
  }

  logoutIcon(){
    return IconoirLogOut;
  }

  userGuideIcon(){
    return IconoirBookStack;
  }
  
  addIcon(){
    return MotifContentIcAdd24px;
  }

  collapseIcon(){
    return IconoirMinus;
  }

  infoIcon(){
    return IconoirInfoCircle;
  }

  uparrowIcon(){
    return MotifHardwareIcKeyboardArrowUp24px;
  }
  aboutIcon()
  {
    return IconoirPrivacyPolicy;
  }

  getProfile() {    
    this.workspaceService.setCurrentUser(this.name);
    this.userService.getUserImage(this.authService.instance.getActiveAccount().localAccountId).subscribe((item: any) => {
      if (item != null) {
        this.isProfilePic = true;
        this.profilePic = item;
      }
    });

    this.userService.profilePic.subscribe(
      item => {
        this.profilePic = item?.base64Format
      });
  }

  get workspace(): Workspace {
    return this.workspaceService.getSelectedWorkspace();
  }

  getQuickLinks(): void {
    this.quickLinksService.getQuickLinks().subscribe({
      next: quickLinks => {
          this.quickLinks = quickLinks;
          this.menu.forEach((item) => {
            if (item.label == 'Quicklinks') {
              this.quickLinks.forEach((quicklinkitem) => {
                if (quicklinkitem.description != "Benchmarking") {
                  const childItems: any = {
                    label: quicklinkitem.description,
                    url: quicklinkitem.url
                  }
                  item.subMenu.push(childItems);
                }
              });
            }
          });
        },
      error: () => {
        this.toastr.error(Labels.UnExpectedErrorQuickLinksLoad)
      }
    });
  }

  get navigationBarSubHeader(): string {
    if (this.currentUrl === '/user') {
      return 'User Management';
    } else {
      return 'Workspace Listing';
    }
  }

 
  isRouteInHome() {
    if (
      this.currentUrl === '/home' ||
      this.currentUrl === '/user' ||
      this.currentUrl === '/' ||
      this.currentUrl === ''
    ) {
      return true;
    }
    return false;
  }

  hasUserEntitlement(entitlementsArray: string[]): boolean {
    let entitled = false;

    this.userEngagementEntitlement = this.entitlementService.getRolesEntitlement();

    if (this.userEngagementEntitlement !== undefined) {
      this.userEngagementEntitlement.forEach((element) => {
        element.userEntitlementRoleVM.forEach((entitlement) => {
          if (entitlementsArray.indexOf(entitlement.code) > -1) {
            entitled = true;
            return entitled;
          }
        });
      });
    }
    return entitled;
  }

  preventNavigation(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  logout() {
    this.sessionService.logoutSession();
  }
  openUserGuide() {
    if(!this.isUnauthorized){
    window.open('assets/EYIPOEnablerUserGuide.pdf', '_blank');
    }
  }

  about() {
    if(!this.isUnauthorized){
    const modalOptions = {
      data: {
        backdrop: 'static',
        keyboard: false,
      },
      panelClass:'about-class'
    };
    this.motifModal.open(AboutComponent, modalOptions);
  }
  }

  toggletemplates() {
    const elem:any = document.querySelectorAll('.templateicon');
    elem.forEach(element => {
      element.blur();
    });

    const elemspan:any = document.querySelectorAll('.templateiconspan');
    elemspan.forEach(element => {
      element.blur();
    });
    if (!this.showTemplates) {
      this.showTemplates = true;
    }
    else {
      this.showTemplates = false;
    }
  }

  mouseOutcss(e){
    const elem: any = document.querySelectorAll('motif-icon');
    elem?.forEach(item=>{
      item.blur();
    })
  }
  
  showPrivacyPolicy() {
    this.motifModal.open(PrivacyPolicyComponent, {
      id: "privacyPolicyComponent",
      data: {
        initialState: {
          title: Labels.modalTitle,
          saveBtnName: Labels.modalBtnName,
          isReadOnly: true
        },
        backdrop: 'static',
        keyboard: false,
      },
      size: ModalSizes.L,
      panelClass:'privacypolicy'
    });
  }

  toggleAddIcon(event:any){
      this.isAddIcon=!this.isAddIcon;
      event.stopPropagation(); 
  }

  navigateQuickLink(description:any){
    if(description=='IPOE readiness assessment report general template'){
      this.downloadLink('assets/New Product Launch Readiness Assessment General template.pptx');
    }
    else{
      let quicklink = this.quickLinks.find(i => i.description == description).url;
      if (description === "Key EY Contacts") {
        this.downloadLink(quicklink);
      } else {
        window.open(quicklink, "_blank");
      }
      
    }
  }

  downloadLink(quicklink: string) {
    const link = document.createElement('a');
    link.href = quicklink;
    link.download = quicklink.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
} 
