export class Workspace {
  id: number;
  guid: string;
  clientName: string;
  name: string;
  user: string;
  industryId: string;
  countryId: string;
  city: string;
  isProvisioned: boolean;
  partnerName: string;
  seniorManagerName: string;
  modifiedBy: string;
  modifiedOn:string;
  createdBy: string;
  createdOn:string;
  country:Country;
  countryName:string;
}
export class Country{
  guid: string;
  id:number;
  name:string;
}

