import { Component } from '@angular/core';
import { SessionService } from '../../session-service.service';
import { MotifModalModule, MotifModule } from '@ey-xd/ng-motif';

@Component({
  selector: 'app-renew-session',
  standalone: true,
  imports: [MotifModule, MotifModalModule],
  templateUrl: './renew-session.component.html',
  styleUrls: ['./renew-session.component.css']
})

export class RenewSessionComponent {
  sec = 0;
  constructor(private sessionService: SessionService) {
    this.sessionService.inactiveSessionExpiry.subscribe(item=>{
      this.sec = item;
    })
   }

  logout(e){
    this.sessionService.logoutSession();
  }
  continueWorking(){
    this.sessionService.renewSession();
  }
}
