import { importProvidersFrom } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideRouter, RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from "@angular/common/http";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { MOTIF_DATE_PICKER_LOCALE, MotifModalModule } from "@ey-xd/ng-motif";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";
import { CookieService } from "ngx-cookie-service";
import { QuillModule } from "ngx-quill";
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService } from "@azure/msal-angular";
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from "../factory";
import { appRoutes } from "../app-routing.module";
import { ErrorInterceptorProvider } from "../_services/error.interceptor";
import { AuthGuard } from "../session_management/auth.guard";
import { LoggingService } from "../_services/logging.service";
import { EngagementGuard } from "../_guard/engagement.guard";
import { PubSubService } from "../_services/pubsub.service";
import { BenchmarkService } from "../_services/benchmark.service";
import { OwnerService } from "../_services/owner.service";
import { SessionInterceptor } from "../session_management/session.interceptor";
import { checkDirtyState } from "../readiness/questionairrelibrary/checkFormDirtyState";
import { SettingsProvider } from "../settingsProvider";
import { SessionService } from "../session_management/session-service.service";
import { DatePipe } from "@angular/common";

export const SHARED_PROVIDERS = [
        provideRouter(appRoutes),
        importProvidersFrom([
            BrowserAnimationsModule,
            AlertModule.forRoot(),
            BsDropdownModule.forRoot(),
            RouterModule.forRoot(appRoutes, {}),
            ModalModule.forRoot(),
            MotifModalModule,
            AccordionModule.forRoot(),
            CollapseModule.forRoot(),
            TooltipModule.forRoot(),
            TypeaheadModule.forRoot(),
            ButtonsModule.forRoot(),
            BsDatepickerModule.forRoot(),
            QuillModule.forRoot(),
            MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory())]
        ),
        provideCharts(withDefaultRegisterables()),
        ErrorInterceptorProvider,
        AuthGuard,
        EngagementGuard,
        LoggingService,
        PubSubService,
        BenchmarkService,
        OwnerService,
        DatePipe,
        { provide: MOTIF_DATE_PICKER_LOCALE, useValue: 'en-GB' },
        {
                provide: HTTP_INTERCEPTORS,
                useClass: MsalInterceptor,
                multi: true
        },
        {
                provide: HTTP_INTERCEPTORS,
                useClass: SessionInterceptor,
                multi: true
        },
        {
                provide: 'canDeactivate',
                useValue: checkDirtyState
        },
        SettingsProvider,
        CookieService,
        SessionService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
    ]